<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Documents')"
    ok-title="Accept"
    size="lg"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-overlay
      :show="dataLoading"
      rounded="sm"
    >
      <b-row>
        <b-col cols="5">
          <b-form-input
            id="register-name"
            v-model="name"
            name="register-name"
            :placeholder="$t('Name')"
          />
        </b-col>
        <b-col cols="5">
          <v-select
            id="register-action"
            v-model="type"
            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
            label="name"
            :options="OptionType"
            :reduce="(OptionType) => OptionType.value"
            :clearable="false"
          />
        </b-col>
        <b-col cols="2">
          <b-button
            variant="primary"
            class="mr-2"
            block
            @click="add()"
          >
            {{ $t('Submit') }}
          </b-button>
        </b-col>
      </b-row>
      <b-table
        ref="refUserListTable"
        striped
        responsive
        class="position-relative"
        :items="lists"
        style="height: 60vh;"
        :fields="tableColumns"
        primary-key="_id"
        show-empty
        :empty-text="$t('No matching records found')"
      >

        <!-- Column: Action -->
        <template #cell(action)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item @click="deleteData(data.item._id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50">{{ $t("Delete") }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-overlay>

    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class=""
            @click="$emit('update:is-modal-active', false)"
          >
            {{ $t("Close") }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
} from 'bootstrap-vue'
import { required } from '@validations'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BOverlay,
    BTable,
    BDropdown,
    BDropdownItem,

  },
  directives: {
    Ripple,
  },
  mixins: [],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      name: '',
      type: 'file',
      lists: [],
      dataLoading: false,
      required,
    }
  },
  computed: {
    OptionType() {
      return [
        { name: this.$t('File'), value: 'file' },
        { name: this.$t('Image'), value: 'image' },
      ]
    },
    tableColumns() {
      return [
        { key: 'name', label: this.$t('Name'), sortable: false },
        { key: 'type', label: this.$t('Type'), sortable: false },
        { key: 'action', label: this.$t('ACTION'), sortable: false },
      ]
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        this.get()
        if (Object.entries(this.data).length === 0) {
          this.initValues()
        } else {
          const {} = this.data
        }
      }
    },
  },
  methods: {
    deleteData(id) {
      this.$bvModal
        .msgBoxConfirm(`${this.$t('Do you want to delete')}?`, {
          title: this.$t('Please Confirm'),
          cancelVariant: 'outline-secondary',
          okVariant: 'danger',
          okTitle: this.$t('Yes'),
          cancelTitle: this.$t('No'),
          centered: true,
        })
        .then(value => {
          if (value === true) {
            this.dataLoading = true
            store
              .dispatch(`${this.storeModuleName}/deleteDocs`, id)
              .then(result => {
                this.dataLoading = false
                const index = this.lists.findIndex(e => e._id.toString() === id.toString())
                if (index > -1) {
                  this.lists.splice(index, 1)
                }
              })
              .catch(error => {
                this.dataLoading = false
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
              })
          }
        })
    },
    add() {
      const obj = {
        name: this.name,
        type: this.type,
      }
      store
        .dispatch(`${this.storeModuleName}/addDocs`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
          this.lists.push(result.data.data)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    get() {
      const obj = {
      }
      this.dataLoading = true
      store
        .dispatch(`${this.storeModuleName}/getDocs`, obj)
        .then(result => {
          this.dataLoading = false
          this.lists = result.data.data
          // this.showToast('success', 'top-right', 'fetch Success', 'CheckIcon')
        })
        .catch(error => {
          this.dataLoading = false
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'top-right', 'FetchUsers Error ', 'XCircleIcon')
        })
    },
    initValues() {},
    submitData() {},
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
