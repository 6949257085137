<template>
  <b-modal
    :visible="isModalActive"
    :title="$t('Documents')"
    ok-title="Accept"
    size="lg"
    @change="(val) => $emit('update:is-modal-active', val)"
  >
    <b-overlay
      :show="dataLoading"
      rounded="sm"
    >
      <b-table
        ref="refUserListTable"
        striped
        responsive
        class="position-relative"
        :items="documents"
        style="height: 60vh;"
        :fields="tableColumns"
        primary-key="_id"
        show-empty
        :empty-text="$t('No matching records found')"
      >

        <!-- Column: Action -->
        <template #cell(check)="data">
          <feather-icon
            v-if="data.item.verified === true"
            color="green"
            icon="CheckCircleIcon"
            size="18"
            @click="documents[data.index].verified = false"
          />
          <feather-icon
            v-if="data.item.verified === false"
            color="red"
            icon="XCircleIcon"
            size="18"
            @click="documents[data.index].verified = true"
          />
        </template>
        <template #cell(document)="data">
          <span
            v-if="data.item.type === 'file'"
            class="cursor-pointer"
            @click="clickFile(data.item.answerFile)"
          >
            <feather-icon
              icon="FileIcon"
              size="25"
            />
            {{ data.item.answerFile.split("/").at(-1) }}
          </span>
          <template v-if="data.item.type === 'image'">
            <b-img
              v-if="data.item.answerImage"
              ref="previewEl"
              rounded
              :src="data.item.answerImage"
              height="100"
              class="cursor-pointer"
              @click="clickFile(data.item.answerImage)"
            />
            <span v-if="data.item.answerImage === null">{{ $t('Not uploaded') }}</span>
          </template>
        </template>
      </b-table>
    </b-overlay>

    <template #modal-footer>
      <div class="w-100">
        <p class="float-left mb-0" />
        <div class="float-right">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="success"
            size="sm"
            class="mr-1"
            @click="save"
          >
            {{ $t("Save") }}
          </b-button>
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            size="sm"
            class=""
            @click="$emit('update:is-modal-active', false)"
          >
            {{ $t("Close") }}
          </b-button>
        </div>
      </div>
    </template>
  </b-modal>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'

import {
  BModal,
  BRow,
  BCol,
  BCardText,
  BFormCheckbox,
  BButton,
  BOverlay,
  BInputGroup,
  BInputGroupAppend,
  BFormInput,
  BTable,
  BDropdown,
  BDropdownItem,
  BImg,
} from 'bootstrap-vue'
import { required } from '@validations'

import Ripple from 'vue-ripple-directive'
import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

import store from '@/store'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BModal,
    // eslint-disable-next-line vue/no-unused-components
    BRow,
    // eslint-disable-next-line vue/no-unused-components
    BCol,
    // eslint-disable-next-line vue/no-unused-components
    BCardText,
    // eslint-disable-next-line vue/no-unused-components
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    BButton,
    BInputGroup,
    BInputGroupAppend,
    BFormInput,
    BOverlay,
    BTable,
    BDropdown,
    BDropdownItem,
    BImg,
  },
  directives: {
    Ripple,
  },
  mixins: [],
  model: {
    prop: 'isModalActive',
    event: 'update:is-modal-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    isModalActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      dataId: null,
      documents: [],
      dataLoading: false,
      required,
    }
  },
  computed: {
    tableColumns() {
      return [
        { key: 'check', label: this.$t('Check Document'), sortable: false },

        {
          key: 'document',
          label: this.$t('Document'),
          sortable: false,
        },
      ]
    },
  },
  watch: {
    isModalActive(val) {
      if (val) {
        this.initValues()

        if (Object.entries(this.data).length === 0) {
        } else {
          const { userId, documents } = this.data
          this.dataId = userId._id
          this.documents = documents
        }
      }
    },
  },
  methods: {
    initValues() {
      this.dataId = null
      this.documents = []
    },
    clickFile(url) {
      window.open(url)
    },
    save() {
      console.log(this.documents)
      const obj = {
        id: this.dataId,
        documents: this.documents,
      }
      store
        .dispatch(`${this.storeModuleName}/saveCheckDocs`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')

        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
